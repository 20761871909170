

// import React from 'react';
// import img from './Img2 copy.jpeg';

// function App() {
//   return (
//     <div className="min-h-screen bg-gray-100 font-inter p-12">
//       <div className="flex justify-between items-center">

//         {/* Image on the Left */}
//         <img
//           src={img}
//           alt="Soham"
//           className="w-48 h-48 rounded-full border-4 border-gray-300"
//         />

//         {/* Content on the Right */}
//         <div className="flex-grow ml-20">
//           <h1 className="text-5xl text-gray-800 font-bold">
//             Hi, I'm Soham
//           </h1>

//           <div className="mt-8">
//             <h2 className="text-3xl text-gray-700 font-semibold">About me</h2>
//             <p className="mt-4 text-base text-gray-600 leading-relaxed">
//             I'm currently studying Computer Science at Purdue University. I'm passionate about full-stack development, and I'm always looking to learn new things and building something new. My current interests lie in blockchain development, Algorithms, and the Austrian School of Economics.
//               Outside the classroom, I've got my hands full with some pretty cool projects.
//               I'm passionate about web3 and I'm the Dev lead at Purdue's Blockchain Club, and I teach a class on Solidity! Aside from that, a couple of friends and I have been building a travel app that we're really excited about, check it out 
//               <a className = "text-blue-500" href="https://www.roamer.dev"> here</a>
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* Interests Section */}
//       <div className="mt-20">
//         <hr className="border-t border-gray-300 mb-8"/>
//         <h2 className="text-3xl text-gray-700 font-semibold">Interests</h2>
//         <p className="mt-4 text-gray-600 leading-relaxed">
//         Diving into hackathons, especially web3 hackathons, has been a massive part of my journey at college. There's just this unique sense of accomplishment in seeing an idea spring to life overnight. When I'm looking to detach a bit from the tech world, you'll either find me strumming away on my acoustic guitar or cruising the campus on my skateboard. Both are my go-to escapes. I also enjoy a good movie once in a while. I'm a sucker for a good plot twist. 
//         </p>
//       </div>

//       {/* Get in touch Section */}
//       <div className="mt-20">
//         <h2 className="text-3xl text-gray-700 font-semibold">Get in touch</h2>
//         <div className="mt-4 space-x-6 text-blue-600">
//           <a href="https://twitter.com/jog_soham" className="hover:text-blue-700 transition duration-300">Twitter</a>
//           <a href="https://github.com/sohamjog/" className="hover:text-blue-700 transition duration-300">GitHub</a>
//           <a href="https://www.linkedin.com/in/soham-jog-ba4b62237/" className="hover:text-blue-700 transition duration-300">LinkedIn</a>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import img from './Img2 copy.jpeg';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 font-inter p-4 md:p-12">
      <div className="flex flex-col md:flex-row justify-between items-center">

        {/* Image on the Left */}
        <img
          src={img}
          alt="Soham"
          className="w-40 h-40 md:w-48 md:h-48 rounded-full border-4 border-gray-300 mb-8 md:mb-0"
        />

        {/* Content on the Right */}

        <div className="flex-grow md:pt-32 md:ml-20">
          <h1 className="text-4xl md:text-5xl text-gray-800 font-bold">
            Hi, I'm Soham
          </h1>

          <div className="mt-8">
            <h2 className="text-2xl md:text-3xl text-gray-700 font-semibold">About me</h2>
            <p className="mt-4 text-base text-gray-600 leading-relaxed">
            I'm currently studying Computer Science at Purdue University, where I spend most of my time learning, building, and figuring out how things work. I’m really into full-stack development and love diving into new projects. Lately, my interests have shifted towards blockchain development, algorithm design, and distributed systems—always something new to explore!
            </p>

            <p className="mt-4 text-base text-gray-600 leading-relaxed">
            This past summer, I had the awesome opportunity to intern at Meta, where I worked on the Developer Integrity team, helping protect the Meta API. It was a great experience, and I learned a lot about safeguarding large-scale platforms.
            </p>

            <p className="mt-4 text-base text-gray-600 leading-relaxed">
            Now I’m back at school, leading <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://www.boilerblockchain.org/" target="_blank" rel="noopener noreferrer" >Purdue’s Blockchain Club,</a>  where I also teach a class on Solidity. On top of that, a few friends and I are building a low-latency course-search tool that we’re pretty excited about—check it out <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://www.boilerclasses.com/" target="_blank" rel="noopener noreferrer" >here</a>.
            </p>

            <p className="mt-4 text-base text-gray-600 leading-relaxed">
            I’m also digging into some research, currently working on a Rust library for Reed-Solomon Error Correction Codes. You can find it <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://github.com/SohamJog/reed_solomon_rs" target="_blank" rel="noopener noreferrer" >here</a>.
            </p>

            <p className="mt-4 text-base text-gray-600 leading-relaxed">
            Some other things I've built are <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://ethglobal.com/showcase/soho-xo1fi" target="_blank" rel="noopener noreferrer" >Soho</a>, <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://github.com/visvshah/Pulse" target="_blank" rel="noopener noreferrer" >Pulse</a>, <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://ethglobal.com/showcase/daolingo-fd6uw" target="_blank" rel="noopener noreferrer" >DaoLingo</a>, and <a className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100" href="https://www.roamer.dev/" target="_blank" rel="noopener noreferrer" >Roamer</a>.
            </p>

          </div>
        </div>
      </div>
      <div className="mt-8">

      <hr className="border-t border-gray-300 mb-8"/>
      </div>
      {/* Interests Section */}
      {/* <div className="mt-16">
        
        <h2 className="text-2xl md:text-3xl text-gray-700 font-semibold">Interests</h2>
        <p className="mt-4 text-gray-600 leading-relaxed">
        Diving into hackathons, especially web3 hackathons, has been a massive part of my journey at college. There's just this unique sense of accomplishment in seeing an idea spring to life overnight. When I'm looking to detach a bit from the tech world, you'll either find me strumming away on my acoustic guitar or cruising the campus on my skateboard. Both are my go-to escapes. I also enjoy a good movie once in a while. I'm a sucker for a good plot twist. 
        </p>
      </div> */}

      {/* Get in touch Section */}
      <div className="mt-16">
        <h2 className="text-2xl md:text-3xl text-gray-700 font-semibold">Get in touch</h2>
        <div className="mt-4 space-x-4 md:space-x-6 text-blue-600">
          <a href="https://twitter.com/jog_soham" target="_blank" rel="noopener noreferrer"  className="no-underline border-b-2 border-dotted border-gray-500  hover:bg-blue-100 text-black">Twitter</a>
          <a href="https://github.com/sohamjog/" target="_blank" rel="noopener noreferrer"  className="no-underline border-b-2 border-dotted border-gray-500  hover:bg-blue-100 text-black">GitHub</a>
          <a href="https://www.linkedin.com/in/soham-jog-ba4b62237/" target="_blank" rel="noopener noreferrer"  className="no-underline border-b-2 border-dotted border-gray-500  hover:bg-blue-100 text-black">LinkedIn</a>
          <a href="mailto:jogsoham2003@gmail.com" target="_blank" rel="noopener noreferrer"  className="no-underline border-b-2 border-dotted border-gray-500 hover:bg-blue-100 text-black">Email</a>
        </div>
      </div>
    </div>
  );
}

export default App;
